import { GetServerSideProps, NextPage } from 'next';

import React, { useCallback, useContext, useEffect } from 'react';

import { Button, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import Cookies from 'js-cookie';

import { BackyardGlobalContext } from '@src/context';
import { FunwooAPI } from '@src/swagger';

const SignIn: NextPage<{ redirectURL: string }> = ({ redirectURL }) => {
  const { setLoading } = useContext(BackyardGlobalContext);
  const { push } = useRouter();
  const onPress = useCallback(async () => {
    setLoading(true);
    await push(redirectURL);
    setLoading(false);
  }, [redirectURL]);
  const onEmailPress = useCallback(() => {
    push('/auth/email');
  }, []);
  useEffect(() => {
    Cookies.remove('x-funwoo-token');
  }, []);
  return (
    <Stack
      spacing={8}
      justify='center'
      align='center'
      h='100vh'
      direction='column'
    >
      <Button
        w='32rem'
        h='4rem'
        fontSize='2rem'
        variant='outline'
        onClick={onPress}
      >
        Sign in with Google
      </Button>
      <Button
        w='32rem'
        h='4rem'
        fontSize='2rem'
        variant='outline'
        onClick={onEmailPress}
      >
        Or Email ...
      </Button>
    </Stack>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const {
    data: { authURL },
  } = await FunwooAPI.authApi.issueGoogleOAuth({
    state: `${process.env.BACKYARD_WEB_DOMAIN || context.req.headers.host
      }/api/auth/identity`,
  });
  return {
    props: {
      redirectURL: authURL,
    },
  };
};

SignIn.fullScreenLayout = true;
export default SignIn;
